<template>
  <b-card>
    <b-form class="mt-2">
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-checkbox
            id="accountSwitch1"
            v-model="notification.ticket.status"
            checked="checked"
            name="check-button"
            switch
            inline
          >
            <span>{{ $t('profile_notification_ticket') }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-checkbox
            id="accountSwitch2"
            v-model="notification.announcements.status"
            checked="checked"
            name="check-button"
            switch
            inline
          >
            <span>{{ $t('profile_notification_announcements') }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-checkbox
            id="accountSwitch3"
            v-model="notification.login.status"
            name="check-button"
            switch
            inline
          >
            <span>{{ $t('profile_notification_login') }}</span>
          </b-form-checkbox>
        </b-col>
        <!--/ application switch -->
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-if="!pending"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 mt-1"

            @click="submit"
          >
            {{ $t('save_changes') }}
          </b-button>
          <b-button
            v-if="pending"
            variant="primary"
            class="mt-1 mr-1"
            disabled
          >
            <b-spinner
              small
              type="grow"
            />
            <span>{{ $t('loading') }}</span>
          </b-button>
        </b-col>
      <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox, BForm, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ProfileApis from '@/services/apis/profile'
import { alphanumeric } from '@/mixins/alphanumeric'

const userProfile = new ProfileApis()
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BForm,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      pending: false,
      notification: {
        login: {
          name: 'login',
          status: false,
        },
        ticket: {
          name: 'ticket',
          status: false,
        },
        announcements: {
          name: 'announcements',
          status: false,
        },
      },
    }
  },
  computed: {
    profileNotification() {
      return this.$store.state.profile.profileInfo.notification
    },
  },
  watch: {
    profileNotification(val) {
      if (val) {
        this.notification = {
          login: {
            name: 'login',
            status: val.login,
          },
          ticket: {
            name: 'ticket',
            status: val.ticket,
          },
          announcements: {
            name: 'announcements',
            status: val.announcement,
          },

        }
      }
    },
  },
  methods: {
    async submit() {
      this.pending = true
      const data = {
        notification: this.notification,
      }

      await userProfile.saveNotification(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        this.pending = false
        userProfile.get()
      }).finally(() => {
        this.pending = false
      })
    },
  },
}
</script>
